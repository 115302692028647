import { AntboxError, Either } from "../deps";

export function handleEither<T>(cb: (v: T) => void): (v: Either<AntboxError, T>) => void {
    return (v: Either<AntboxError, T>) => {
        if (v.isLeft()) {
            console.error(v.value);
            return;
        }

        cb(v.value);
    };
}

export function handleException(e: Error) {
    console.error(e);
}
