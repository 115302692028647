import { aspectServiceClient, nodeServiceClient } from "~/lib/deps";

export default () => {
    const { public: { antboxUrl, tenant } } = useRuntimeConfig()

    if (!antboxUrl) {
        throw new Error("Não foi possível encontrar a configuração do URL do antbox.");
    }

    return {
        antboxNodeServiceClient: nodeServiceClient({ url: antboxUrl, tenant }),
        antboxAspectServiceClient: aspectServiceClient({ url: antboxUrl, tenant }),
        tenant   
    }
}
